<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit" :class="{ disabled: stock.deliveryStatus === 'CANCELLED' }">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.stock.description.$errors.length }"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.description"
                            id="description"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.description.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.partNumber.$errors.length }"
                        :label="$t('forms.partNumberLong')"
                        label-for="partNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.partNumber"
                            id="partNumber"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.partNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.batchNumber.$errors.length }"
                        :label="$t('forms.batchNumberLong')"
                        label-for="batchNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.batchNumber"
                            id="batchNumber"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.batchNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.typeSelect.$errors.length }"
                        :label="$t('forms.type')"
                        label-for="type"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="type"
                            v-model="typeSelect"
                            :options="types"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.typeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.statusSelect.$errors.length }"
                        :label="$t('forms.status')"
                        label-for="statusSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="statusSelect"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.statusSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.owner')"
                        label-for="owner"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            :class="{ row, error: v$.ownerSelect.$errors.length }"
                            v-model="ownerSelect"
                            :options="owners"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.ownerSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="{ row, error: v$.stock.quantity.$errors.length }"
                        :label="$t('forms.quantity')"
                        label-for="quantity"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.quantity"
                            id="quantity"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.quantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="{ row, error: v$.stock.reorderQuantity.$errors.length }"
                        :label="$t('forms.reorderQuantity')"
                        label-for="reorderQuantity"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.reorderQuantity"
                            id="reorderQuantity"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.reorderQuantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.reorderNumber')"
                        label-for="reorderNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.reorderNumber"
                            id="reorderNumber"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.price.$errors.length }"
                        :label="$t('forms.priceEUR')"
                        label-for="price"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.price"
                            id="price"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.price.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.priceUSD.$errors.length }"
                        :label="$t('forms.priceUSD')"
                        label-for="priceUSD"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.priceUSD"
                            id="priceUSD"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.priceUSD.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.exchangeRate')"
                        label-for="exchangeRate"
                        label-cols-md="4"
                        disabled="disabled"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.exchangeRate"
                            id="exchangeRate"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">
                    <b-form-group
                        class="row"
                        :label="$t('forms.expiryDate')"
                        label-for="expiryDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="expiryDate"
                            v-model="stock.expiryDate"
                            :enableTimePicker="false"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.icInterval')"
                        label-for="icInterval"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="icInterval"
                            v-model="stock.icInterval"
                            :enableTimePicker="false"
                            :minDate="new Date()"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.locationSelect.$errors.length }"
                        :label="$t('forms.storageLocation')"
                        label-for="location"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="location"
                            v-model="locationSelect"
                            :options="locations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.locationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.dangerous')"
                        label-for="dangerous"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="stock.dangerous" id="dangerous" switch/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.heavy')"
                        label-for="heavy"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="stock.heavy" id="heavy" switch/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.station')"
                        label-for="station"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="station"
                            v-model="stock.station"
                            :options="stations"
                            taggable
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.aeiNumber')"
                        label-for="aeiNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.aeiNumber"
                            id="aeiNumber"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.effectivity')"
                        label-for="effectivity"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.effectivity"
                            id="effectivity"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.orderNumber')"
                        label-for="orderNumber"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.orderNumber"
                            id="orderNumber"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.supplier')"
                        label-for="supplier"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.supplier"
                            id="supplier"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.remark')"
                        label-for="remark"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="stock.remark"
                            id="remark"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {decimal, numeric, required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        stockObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(["stock_item_status"], this.statuses);
        this.getResourceList(this.$StockItemTypes, this.types)
        this.getResourceList(this.$StockItemOwners, this.owners)
        this.loadStations();
        this.loadLocations();
        if ('id' in this.stock) {
            if (this.stock.type) {
                this.typeSelect = {
                    label: this.stock.type.name,
                    id: this.stock.type['@id'],
                };
            }
            if (this.stock.owner) {
                this.ownerSelect = {
                    label: this.stock.owner.name,
                    id: this.stock.owner['@id'],
                };
            }
            if (this.stock.location) {
                this.locationSelect = {
                    label: this.stock.location.station.name + ' - ' + this.stock.location.stockItemLocation,
                    id: this.stock.location['@id']
                }
            }
            if (this.stock.transport) {
                this.transportSelect = {
                    label: this.$helper.getEnumTranslation('delivery_item_transport', this.stock.transport, this.$i18n.locale),
                    id: this.stock.transport
                };
            }
            if (this.stock.deliveryStatus) {
                this.deliveryStatusSelect = {
                    label: this.$helper.getEnumTranslation('delivery_item_status', this.stock.deliveryStatus, this.$i18n.locale),
                    id: this.stock.deliveryStatus
                };
            }
            this.statusSelect = {
                label: this.$helper.getEnumTranslation('stock_item_status', this.stock.status, this.$i18n.locale),
                id: this.stock.status
            };

        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            statusSelect: {required},
            typeSelect: {required},
            ownerSelect: {required},
            locationSelect: {required},
            stock: {
                description: {required},
                partNumber: {required},
                quantity: {required, numeric},
                price: {decimal},
                priceUSD: {decimal},
                batchNumber: {required},
                reorderQuantity: {numeric},
            },
        };
    },
    data() {
        return {
            stock: this.stockObject,
            types: [],
            typeSelect: null,
            owners: [],
            ownerSelect: null,
            locations: [],
            locationSelect: null,
            statuses: [],
            statusSelect: null,
            stations: [],
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        loadLocations() {
            const params = {
                pagination: false
            }
            this.$StockItemLocations.getCollection({params}, "stock_item_location").then((response) => {
                response.data["hydra:member"].forEach(element => {
                    this.locations.push({
                        label: element.station.name + ' - ' + element.stockItemLocation,
                        id: element['@id']
                    })
                });
            });
        },
        loadStations() {
            const params = {
                pagination: false
            }
            this.$Stations.getCollection({params}, "id_name").then((response) => {
                response.data["hydra:member"].forEach(element => {
                    this.stations.push(element.name)
                });
            });
        },
        async onSubmit() {
            if (this.action !== "update" && this.action !== "add") {
                return
            }
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.typeSelect) {
                    this.stock.type = this.typeSelect.id
                }
                if (this.ownerSelect) {
                    this.stock.owner = this.ownerSelect.id
                }
                if (this.locationSelect) {
                    this.stock.location = this.locationSelect.id
                }
                if (this.statusSelect) {
                    this.stock.status = this.statusSelect.id
                }
                // this.stock.quantity = this.stock.quantity ? parseFloat(this.stock.quantity) : this.stock.quantity
                this.stock.reorderQuantity = this.stock.reorderQuantity ? parseInt(this.stock.reorderQuantity) : this.stock.reorderQuantity
                this.stock.price = this.stock.price === '' ? null : this.stock.price;
                this.stock.priceUSD = this.stock.priceUSD === '' ? null : this.stock.priceUSD;
                if (this.action === "add") {
                    this.create(this.$StockItems, this.stock, this.$t("messages.stockItemsCreated"), null, this.success, this.error);
                }
                if (this.action === "update") {
                    this.update(this.$StockItems, this.stock.id, this.stock, this.$t("messages.stockItemsUpdated"), null, this.success, this.error);
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/stock-items`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

